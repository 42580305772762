<template>
  <div id="Authority">
    <p>手机快捷登陆</p>
    <p>未注册过的手机号将自动创建账号</p>
    <div class="form">
      <div>
        <p>手机号</p>
        <input v-model="phone" placeholder="请输入手机号" />
        <p :class="{'active':phone}" @click="toSend">{{flag ? '已发送'+time+'s' : '获取验证码'}}</p>
      </div>
      <div>
        <p>验证码</p>
        <input v-model="code1" placeholder="请输入验证码" />
      </div>
    </div>
    <div class="editSure">
      <div :class="{'active':!(phone && code1)}"  @click="toInsure">确定</div>
    </div>
  </div>
</template>

<script>
// 授权
export default {
  name: 'Authority',
  data () {
    return {
      appid: this.Global.appid,
      url:this.Global.nowurl,
      domain:this.Global.domain,
      route:'/Authority',
      redirectUrl: '',
      code: '',//微信授权
      code1: '',//验证码
      phone:'',
      time:60,
      flag:false,
      signCode:''
    }
  },
  methods: {
    // 手机号登录验证
    toInsure(){
      let url = window.localStorage.getItem("beforeLoginUrl1") || '/';
      if(!(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone))){ 
        this.$toast('手机号码有误')
        return false; 
      } 
      if(!this.code1){ 
        this.$toast('请填写验证码')
        return false; 
      }
      this.axios({
        method: 'POST',
        url: '/sms/verify',
        data:{
          phone:this.phone,
          code:this.code1,
          signCode:this.signCode
        },
        headers: {
          'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}` 
        }
      }).then((res) => {
        if(res.data.code == 0){
          localStorage.removeItem('getnewAppid')
          localStorage.removeItem('beforeLoginUrl1')
          localStorage.removeItem('beforeLoginUrl')
          this.Global.setCookie('tokenDkhNww',res.data.data.token, res.data.data.expires_in)
          // this.getWX();
          this.$router.push(url);
        }else{
          this.$toast(res.data.message);
        }
      })

    },
    toSend(){
      console.log(this.phone)
      if(!(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone))){ 
        this.$toast('手机号码有误')
        return false; 
      }   
      if(this.flag){
        return false;
      }
      this.axios({
        method: 'POST',
        url: '/sms/send',
        data:{
          phone:this.phone
        },
        headers: {
          'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}` 
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.flag = true
          this.time = 60;
          var _this = this;
          var timer = setInterval(function () {
            if (_this.time > 0) {
              _this.time--;
            } else {
              _this.flag = false
              clearInterval(timer)
            }
          },1000);
          this.$toast(res.data.message);
        }else{
          this.$toast(res.data.message);
        }
      })
    },
    getWX(){
      this.redirectUrl = encodeURIComponent(`${this.url}${this.route}`)
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.redirectUrl}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
    },
    // 这一步拿到signCode，下一步进行手机号登录
    getToken() {
      let url = window.localStorage.getItem("beforeLoginUrl1") || '/';
      console.log(url)
      let getUrl = `/auth/wechat?code=${this.code}`
      let _this = this;
      this.axios({
        method: 'GET',
        url: getUrl,
        headers: {
          'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}` 
        }
      }).then((res) => {
        if(res.data.code == 0){
          console.log(res,'res=====',url,window.localStorage.getItem("beforeLoginUrl1"))

          if(res.data.data.signCode){
            this.signCode = res.data.data.signCode
          }else{
            localStorage.removeItem('getnewAppid')
            localStorage.removeItem('beforeLoginUrl1')
            this.Global.setCookie('tokenDkhNww',res.data.data.token, res.data.data.expires_in)
            this.$router.push(url);
          }
        }else{
          _this.$toast(res.data.message);
          _this.Global.closeWindowNow()
          location.href="about:blank";
          WeixinJSBridge.call('closeWindow');
        }
      })
    }
  },
  created(){
    // 如果没有标记则存储标记并刷新
    if(!localStorage.getItem('getnewAppid')){
      localStorage.setItem('getnewAppid','getnewAppid')
      window.location.reload()
    }else{
      // 否则便是正常登录流程
      this.code = this.Global.getQueryString('code')
      // 有code去登录
      if(this.code){
        this.getToken()
      }else{  //没有token微信授权 获取code
        this.getWX()
      }
    }
    
    // else{
    //   // 没有code获取token  如果有token就请求看是否过期（这个main.js已经看过了  是过期的  如果过期就让他走登录，没过期就不会来这个页面
    //   if(this.Global.getCookie('tokenDkhNww')){
        
    //   }else{
    //     this.Global.closeWindowNow()
    //     location.href="about:blank";
    //     WeixinJSBridge.call('closeWindow');
    //   }
    // }
    // console.log(this.Global,window.localStorage.getItem("beforeLoginUrl1"),this.code)
    // if (!this.code) {
    //   // 没有code，跳转微信授权获取code
    //   this.redirectUrl = encodeURIComponent(`${this.url}${this.route}`)
    //   location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.redirectUrl}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
    // } else {
      // 有code，获取tokenDkhNww  有tokenDkhNww 就退出 无tokenDkhNww再获取
      
    // }
  }
}
</script>

<style lang="less" scoped>

#Authority{
  padding: 0.3rem 0.15rem;
  .editSure{
    text-align: center;
    margin-top: 0.7rem;
    padding: 0 0.15rem 0.11rem;
    >div{
      height: 0.4rem;
      line-height: 0.4rem;
      border-radius: 0.4rem;
      background: #3890FF;
      color: #F6F7FD;
      font-size: 0.13rem;
    }
    .active{
      background: #3D404F;
    }
  }
  .form{
    >div{
      display: flex;
      align-items: center;
      padding: 0.1rem 0;
      border-bottom: .1px solid #5C5D6E61;
      // >div{
        // flex: 1;
        // display: flex;
        // background: pink;
        >input{
          flex: 1;
          display: block;
          background: none;
          border: none;
          color: #ECEDF7;
          // margin-left: 0.3rem;
          font-size: 0.14rem;
          height: 0.3rem;
          min-width: 1rem;
        }
      // }
      >p:first-child{
        color: #989AB1;
        margin-right: 0.3rem;
      }
      >p:last-child{
        height: 0.3rem;
        background: #3D404F;
        border-radius: 0.3rem;
        color: #989AB1;
        font-size: 0.12rem;
        padding: 0 0.16rem;
        line-height: 0.3rem;
      }
      >.active{
        background: #3890FF !important;
        color: #F6F7FD !important;
      }
    }
    >div:last-child{
      margin-top: 0.15rem;
    }
  }
  >p:first-child{
    font-size: 0.25rem;
    line-height: 0.38rem;
    color: #F6F7FD;
  }
  >p:nth-child(2){
    margin: 0.05rem 0 0.6rem;
    font-size: 0.17rem;
    line-height: 0.24rem;
    color: #727387;
  }
}
input::-webkit-input-placeholder{
      color:#565969;
  }
  input::-moz-placeholder{   /* Mozilla Firefox 19+ */
      color:#565969;
  }
  input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
      color:#565969;
  }
  input:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
      color:#565969;
  }
</style>